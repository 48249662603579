<template>
  <component :is="accessDeniedReason" :plan="companyPlan" />
</template>

<script>
import { getCompanyPlans } from '@/repository/companies-app/company-plans.js'
import CompletedPlan from '@/pages/AccessVerification/CompletedPlan.vue'
import ExpiredPlan from '@/pages/AccessVerification/ExpiredPlan.vue'
import LiteEnded from '@/pages/AccessVerification/LiteEnded.vue'
import ExpiredCredits from '@/pages/AccessVerification/ExpiredCredits.vue'
import PayPerHireEnded from '@/pages/AccessVerification/PayPerHireEnded.vue'

export default {
  name: 'AccessDenied',
  components: {
    CompletedPlan,
    ExpiredPlan,
    LiteEnded,
    ExpiredCredits,
    PayPerHireEnded
  },
  data () {
    return {
      companyPlan: undefined,
      loaded: false
    }
  },
  computed: {
    accessDeniedReason () {
      if (this.loaded === false) return
      if (!this.companyPlan?.id) return PayPerHireEnded
      if (this.companyPlan.planName === 'Básico') return PayPerHireEnded
      if (this.companyPlan.planName === 'Light') return LiteEnded
      if (this.companyPlan.completed) return CompletedPlan
      if (this.companyPlan.numberOfPositions) return ExpiredCredits
      return ExpiredPlan
    }
  },
  async created () {
    this.companyPlan = await getCompanyPlans()
    this.loaded = true
  }
}
</script>

<style scoped></style>
