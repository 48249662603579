<template>
  <div class="l-wrapper hard-top with-element element-h120" data-height="70px">
    <div class="completed-plan__body">
      <section class="completed-plan__body-text">
        <h1>
          {{ $tc(
            `${langPath}.header`,
            numberOfPositions,
            { numberOfPositions: numberOfPositions }
          ) }}
        </h1>

        <p>
          <strong> {{ $t(`${langPath}.title`) }} </strong>
          {{ $t(`${langPath}.title2`) }}
        </p>

        <p>
          {{ $t(`${langPath}.talkToUs`) }}
        </p>

        <div class="completed-plan__body-link">
          <ev-icon color="blue" name="envelope" />
          <div class="completed-plan__body-link-element">
            <span>
              <a :href="linkToContactEmail">
                {{ $t(`${langPath}.emailAddress`) }}
              </a>
            </span>
          </div>
        </div>

        <div class="completed-plan__body-link">
          <ev-icon color="blue" name="phone" />
          <div class="completed-plan__body-link-element">
            <span>
              {{ $t(`${langPath}.phoneNumber`) }}
            </span>
          </div>
        </div>

        <ev-link
          :a-href="csWhatsappUrl"
          size="medium"
          target="_blank"
        >
          {{ $t(`${langPath}.talkToManager`) }}
        </ev-link>
      </section>
    </div>
  </div>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { csWhatsappUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'CompletedPlan',
  components: {
    EvLink,
    EvIcon
  },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      csWhatsappUrl
    }
  },
  computed: {
    linkToContactEmail () {
      return 'mailto:empresas@revelo.io'
    },
    numberOfPositions () {
      return this.plan.numberOfPositions
    }
  }
}
</script>

<style lang="scss">
.completed-plan {
  &__body {
    align-items: flex-start;
    background: var(--bg-white);
    border-radius: $base*1;
    box-shadow: 0 $base/2 $base*2 rgba(39, 58, 80, 0.08);
    display: flex;
    flex-direction: column;
    height: $base*101;
    left: $base*131;
    margin: $base*15 auto auto auto;
    padding: $base*10;
    top: $base*31;
    width: $base*101;

    &-button {
      margin: auto auto 0 auto;
    }

    &-text {
      h1 {
        font-family: Antenna, sans-serif;
        font-size: $base*5;
        font-style: normal;
        font-weight: normal;
        line-height: $base*7;
        margin: 0 auto 4px auto;
      }

      b {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      p {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 18px 0;
      }
    }

    &-link {
      align-items: center;
      color: $blue;
      display: flex;
      flex-direction: row;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      height: 24px;
      left: 0;
      line-height: 20px;
      margin: 4px 0;
      margin-bottom: 24px;
      padding: 0;
      position: static;
      text-decoration-line: underline;
      top: 0;
      width: 165px;

      &-element {
        padding-left: 8px;
      }
    }
  }
}

.l-wrapper {
  &.hard-top {
    padding-top: $base*6;
  }

  &.padding-bottom {
    padding-bottom: $base*6;
  }

  &.with-element {
    &::before {
      background-color: $gray100;
      content: '';
      height: 270px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  &.element-h120 {
    &::before {
      height: $base*30;
    }
  }
}
</style>
