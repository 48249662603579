<template>
  <div class="l-wrapper hard-top with-element element-h120" data-height="70px">
    <div class="expired-plan__body">
      <section class="expired-plan__body-text">
        <h1>
          {{ $t(`${langPath}.header`) }}
        </h1>
        <p>
          {{ $t(`${langPath}.title`) }} {{ endDate }}.
        </p>
        <p>
          {{ $t(`${langPath}.talkToUs`) }}
        </p>
        <div class="expired-plan__body-link">
          <ev-icon color="blue" name="envelope" />
          <div class="expired-plan__body-blue">
            <span>
              <a :href="linkToContactEmail">{{ $t(`${langPath}.emailAddress`) }}</a>
            </span>
          </div>
        </div>

        <div class="expired-plan__body-link">
          <ev-icon color="blue" name="phone" />
          <span class="expired-plan__body-link-element">
            {{ $t(`${langPath}.phoneNumber`) }}
          </span>
        </div>

        <ev-link
          :a-href="csWhatsappUrl"
          size="medium"
          target="_blank"
        >
          {{ $t(`${langPath}.talkToManager`) }}
        </ev-link>
      </section>
    </div>
  </div>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { csWhatsappUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'ExpiredPlan',
  components: {
    EvLink,
    EvIcon
  },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      csWhatsappUrl
    }
  },
  computed: {
    linkToContactEmail () {
      return 'mailto:empresas@revelo.io'
    },
    endDate () {
      return this.plan.formattedEndDate
    }
  }
}
</script>

<style lang="scss">
  .expired-plan {
    &__body {
      align-items: flex-start;
      background: var(--bg-white);
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(39, 58, 80, 0.08);
      display: flex;
      flex-direction: column;
      height: $base*101;
      left: $base*131;
      margin: $base*31 auto auto auto;
      padding: $base*10;
      top: $base*31;
      width: 405px;

      &-button {
        margin: auto auto 0 auto;
      }

      &-text {
        h1 {
          font-family: Antenna, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: normal;
          line-height: 28px;
          margin: 0 auto 4px auto;
        }

        b {
          font-family: Open Sans, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        p {
          font-family: Open Sans, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 18px 0;
        }
      }

      &-link {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        height: $base*6;
        left: 0;
        line-height: 20px;
        margin: $base*1 0;
        margin-bottom: $base*6;
        padding: 0;
        position: static;
        top: 0;
        width: $base*42;

        &-element {
          margin-left: 8px;
        }
      }

      &-blue {
        color: $blue;
        margin-left: 8px;
        text-decoration-line: underline;
      }
    }
  }

  .l-wrapper {
    &.hard-top {
      padding-top: $base*6;
    }

    &.padding-bottom {
      padding-bottom: $base*6;
    }

    &.with-element {
      &::before {
        background-color: $gray100;
        content: '';
        height: 270px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }
    }

    &.element-h120 {
      &::before {
        height: $base*30;
      }
    }
  }
</style>
