import { render, staticRenderFns } from "./ExpiredCredits.vue?vue&type=template&id=e6b39708&"
import script from "./ExpiredCredits.vue?vue&type=script&lang=js&"
export * from "./ExpiredCredits.vue?vue&type=script&lang=js&"
import style0 from "./ExpiredCredits.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports