import { get } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/company_plans/last_company_plan`

export async function getCompanyPlans () {
  const response = await get(baseUrl)

  return jsonApiClient.parse(response.data)
}
