<template>
  <div class="l-wrapper hard-top with-element element-h120" data-height="70px">
    <div class="pph-ended__body">
      <div class="pph-ended__body-text">
        <h1>
          {{ $t(`${langPath}.header1`) }}
        </h1>
        <h1>
          {{ $t(`${langPath}.header2`) }}
        </h1>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t(`${langPath}.title`)" />

        <p>
          {{ $t(`${langPath}.talkToUs`) }}
        </p>
        <div class="pph-ended__body-link">
          <ev-icon color="blue" name="envelope" />
          <div class="pph-ended__body-link-element">
            <span>
              <a :href="linkToContactEmail">
                {{ $t(`${langPath}.emailAddress`) }}
              </a>
            </span>
          </div>
        </div>
        <p>
          {{ $t(`${langPath}.talkToSalesText1`) }}
        </p>
        <p>
          {{ $t(`${langPath}.talkToSalesText2`) }}
        </p>
        <p>
          {{ $t(`${langPath}.talkToSalesText3`) }}
        </p>
      </div>

      <div class="pph-ended__body-button">
        <ev-link
          :a-href="salesWhatsappUrl"
          size="medium"
          target="_blank"
        >
          {{ $t(`${langPath}.talkToSalesTeam`) }}
        </ev-link>
      </div>
    </div>
  </div>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { salesWhatsappUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'PayPerHireEnded',
  components: {
    EvLink,
    EvIcon
  },
  data () {
    return {
      langPath: __langpath,
      salesWhatsappUrl
    }
  },
  computed: {
    linkToContactEmail () {
      return 'mailto:empresas@revelo.io'
    }
  }
}
</script>

<style lang="scss">
.pph-ended {
  &__body {
    align-items: flex-start;
    background: var(--bg-white);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(39, 58, 80, 0.08);
    display: flex;
    flex-direction: column;
    height: $base*140;
    left: $base*131;
    margin: $base*31 auto auto auto;
    padding: $base*10;
    top: $base*31;
    width: 405px;

    &-button {
      margin: auto auto 0 auto;
    }

    &-text {
      h1 {
        font-family: Antenna, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 28px;
        margin: 0 auto 4px auto;
      }

      b {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      p {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 18px 0;
      }
    }

    &-link {
      align-items: center;
      color: $blue;
      display: flex;
      flex-direction: row;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      height: $base*6;
      left: 0;
      line-height: $base*5;
      margin: 4px 0;
      margin-bottom: 24px;
      padding: 0;
      position: static;
      text-decoration-line: underline;
      top: 0;
      width: 165px;

      &-element {
        padding-left: $base*2;
      }
    }
  }
}

.l-wrapper {
  &.hard-top {
    padding-top: $base*4;
  }

  &.padding-bottom {
    padding-bottom: $base*4;
  }

  &.with-element {
    &::before {
      background-color: $gray100;
      content: '';
      height: 270px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  &.element-h120 {
    &::before {
      height: $base*40;
    }
  }
}
</style>
